import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';





const IndexPage = ({ pageContext, ...props }) => (
  <Layout
    title="Oops... Como é possível?"
    description="...porque esta página não existe"
  >
    <Header frontPage={false} selected=" " />
    <section className="social-section bg-fg mt-5 mb-5">
      <div className="container-fluid mt-5 mb-5">
        <div className="row d-flex justify-content-center align-items-center mt-5 mb-5">
          <div
            className="col-sm-12 text-center"
            dangerouslySetInnerHTML={{ __html: pageContext.content }}
          ></div>
        </div>
      </div>
    </section>
    <SocialLinks />
    <Footer />
  </Layout>
);



export default IndexPage;
